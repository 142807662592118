import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import QRCodeStyling from "qr-code-styling";
import ReactJson from 'react-json-view'

const demoLogoimage = "/sample/demo_logo.png";

const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  image: demoLogoimage,
  dotsOptions: {
    color: "#4267b2",
    type: "rounded"
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 20
  },
  qrOptions: {
    typeNumber: "0",
    mode: "Byte",
    errorCorrectionLevel: "Q"
  }
});

const dotStypes = [
  'square', 'dots', 'rounded', 'extra-rounded', 'classy', 'classy-rounded'
]
const cornersSquareStyles = [
  'square', 'extra-rounded', 'dot'
]
const cornerDotOptions = [
  'dot', 'square'
]

const downloadImageExtension = [
  'png', 'jpeg', 'webp'
]

export default function App() {
  const [text, setText] = useState("https://smarttrick.org");
  const [fileExt, setFileExt] = useState("png");
  const [width, setWidth] = useState(300);
  const [height, setHeight] = useState(300);
  const [dotStyle, setDotStyle] = useState("square");
  const [dotColor, setDotColor] = useState("#4267b2");
  const [cornerSquareStyle, setCornerSquareStyle] = useState("square");
  const [cornerSquareColor, setCornerSquareColor] = useState("#4267b2");
  const [cornerDotOption, setCornerDotOption] = useState("square");
  const [cornerDotColor, setCornerDotColor] = useState("#4267b2");
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  // image options
  const [hideBackgroundDots, setHideBackgroundDots] = useState(true);
  const [imageSize, setImageSize] = useState(0.4);
  const [imageMargin, setImageMargin] = useState(0);
  const [image, setImage] = useState('https://upload.wikimedia.org/wikipedia/commons/b/b9/2023_Facebook_icon.svg');
  // download options
  const [downloadExt, setDownloadExt] = useState('png');
  const urlParams = new URLSearchParams(window.location.search);

  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    if (width < 100 || height < 100) {
      return;
    }
    qrCode.update({
      data: text,
      width: width,
      height: height,
      image: image,
      dotsOptions: {
        color: dotColor,
        type: dotStyle
      },
      cornersSquareOptions: {
        color: cornerSquareColor,
        type: cornerSquareStyle
      },
      cornersDotOptions: {
        color: cornerDotColor,
        type: cornerDotOption
      },
      backgroundOptions: {
        color: backgroundColor
      },
      imageOptions: {
        hideBackgroundDots: hideBackgroundDots,
        imageSize: imageSize,
        margin: imageMargin
      },
      qrOptions: {
        typeNumber: "0",
        mode: "Byte",
        errorCorrectionLevel: "Q"
      }
    });

  }, [text, width, height, dotStyle, dotColor,
    cornerSquareStyle, cornerSquareColor,
    cornerDotOption, cornerDotColor,
    backgroundColor, 
    imageSize, imageMargin, hideBackgroundDots,
    image
  ]);

  useEffect(() => {
    if (urlParams.has('data')) {
      setText(urlParams.get('data'));
    }
    if (urlParams.has('width')) {
      setWidth(urlParams.get('width'));
    }
    if (urlParams.has('height')) {
      setHeight(urlParams.get('height'));
    }
    if (urlParams.has('image')) {
      setImage(urlParams.get('image'));
    }
    if (urlParams.has('dotColor')) {
      setDotColor(urlParams.get('dotColor'));
    }
    if (urlParams.has('dotStyle')) {
      setDotStyle(urlParams.get('dotStyle'));
    }
    if (urlParams.has('cornerSquareColor')) {
      setCornerSquareColor(urlParams.get('cornerSquareColor'));
    }
    if (urlParams.has('cornerSquareStyle')) {
      setCornerSquareStyle(urlParams.get('cornerSquareStyle'));
    }
    if (urlParams.has('cornerDotColor')) {
      setCornerDotColor(urlParams.get('cornerDotColor'));
    }
    if (urlParams.has('cornerDotOption')) {
      setCornerDotOption(urlParams.get('cornerDotOption'));
    }
    if (urlParams.has('backgroundColor')) {
      setBackgroundColor(urlParams.get('backgroundColor'));
    }
    if (urlParams.has('imageSize')) {
      setImageSize(urlParams.get('imageSize'));
    }
    if (urlParams.has('imageMargin')) {
      setImageMargin(urlParams.get('imageMargin'));
    }
    if (urlParams.has('hideBackgroundDots')) {
      setHideBackgroundDots(urlParams.get('hideBackgroundDots') === 'true');
    }
    if (urlParams.has('ext')) {
      setDownloadExt(urlParams.get('ext'));
    }
    if (urlParams.has('download')) {
      setTimeout(() => {
      }, 300)
    }
  }, [window.location.search])

  const onExtensionChange = (event) => {
    setFileExt(event.target.value);
  };

  const onDownloadClick = () => {
    const currentDate = new Date();
    const imageName = `qrcode_generated_${currentDate.getTime()}`;
    qrCode.download({
      extension: fileExt,
      name: imageName
    });
  };

  const handleImageUpload = (file) => {
    const reader = new FileReader();
    if (!file) {
      return;
    }
    reader.onload = (event) => {
      setImage(event.target.result);
    };
    reader.readAsDataURL(file);
  }

  const copyQROptionsAsJson = () => {
    navigator.clipboard.writeText(qrCodeOptionsAsJson());
    alert("QR code options copied to clipboard!");
  }
  const qrCodeOptionsAsJson = () => {
    const qrOptions = {
      data: text,
      width: width,
      height: height,
      image: image,
      dotsOptions: {
        color: dotColor,
        type: dotStyle
      },
      cornersSquareOptions: {
        color: cornerSquareColor,
        type: cornerSquareStyle
      },
      cornersDotOptions: {
        color: cornerDotColor,
        type: cornerDotOption
      },
      backgroundOptions: {
        color: backgroundColor
      },
      imageOptions: {
        hideBackgroundDots: hideBackgroundDots,
        imageSize: imageSize,
        margin: imageMargin
      }
    };
    return qrOptions
  }

  return (
    <div className="App">
      <div className="custom-from">
        <h1> Free QR Code Generate by Smarttrick.Org </h1>
        <p style={{ textAlign: 'left', fontFamily: 'serif', fontSize: 17 }}>
          Make your QR codes stand out! Our free QR code generator empowers you to personalize your codes with the following features:
          <br/><br/>
          - Upload Your Image: Integrate your logo, a product image, or any relevant visual to enhance brand recognition.
          <br/>
          - Customize Your Text: Clearly communicate the action behind the QR code with a call to action or descriptive text.
          <br/>
          - Color it Up: Select custom colors that match your brand palette or complement your design.
          <br/>
          - Style it Right: Choose from a variety of QR code styles to create a unique and visually appealing code.
          <h4>Get started in seconds - No signup required!</h4>
        </p>
        <table className="custom-table" style={{width: '100%'}}>
          <tbody>
            <tr>
              <td colSpan={2}><b>Main Options</b></td>
            </tr>
            <tr>
              <td>Content</td>
              <td>
                <input
                  type="text"
                  value={text}
                  onChange={(event) => setText(event.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Image File</td>
              <td>
                <input
                  type="file"
                  onChange={(event) => handleImageUpload(event.target.files[0])}
                />
              </td>
            </tr>
            <tr>
              <td>Width</td>
              <td>
                <input
                  type="number"
                  value={width}
                  min={100}
                  onChange={(event) => setWidth(event.target.value)}
                />   
              </td>
            </tr>
            <tr>
              <td>Height</td>
              <td>
                <input
                  type="number"
                  value={height}
                  min={100}
                  onChange={(event) => setHeight(event.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}><b>Dot options</b></td>
            </tr>
            <tr>
              <td>Dot style</td>
              <td>
                <select onChange={(event) => setDotStyle(event.target.value)} value={dotStyle}>
                  {dotStypes.map((style) => (
                    <option key={style} value={style}>{style}</option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>Dot color</td>
              <td>
                <input
                  type="color"
                  value={dotColor}
                  onChange={(event) => setDotColor(event.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}><b>Corners Square Options</b></td>
            </tr>
            <tr>
              <td>Corners Square Style</td>
              <td>
                <select onChange={(event) => setCornerSquareStyle(event.target.value)} value={cornerSquareStyle}>
                  {cornersSquareStyles.map((style) => (
                    <option key={style} value={style}>{style}</option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>Corners Square Color</td>
              <td>
                <input
                  type="color"
                  value={cornerSquareColor}
                  onChange={(event) => setCornerSquareColor(event.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}><b>Corners Dot Options</b></td>
            </tr>
            <tr>
              <td>Corners Dot Options</td>
              <td>
                <select onChange={(event) => setCornerDotOption(event.target.value)} value={cornerDotOption}>
                  {cornerDotOptions.map((style) => (
                    <option key={style} value={style}>{style}</option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>Corners Dot Color</td>
              <td>
                <input
                  type="color"
                  value={cornerDotColor}
                  onChange={(event) => setCornerDotColor(event.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}><b>Background Options</b></td>
            </tr>
            <tr>
              <td>Background Color</td>
              <td>
                <input
                  type="color"
                  value={backgroundColor}
                  onChange={(event) => setBackgroundColor(event.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}><b>Image Options</b></td>
            </tr>
            <tr>
              <td>Hide Background Dots</td>
              <td>
                <input
                  type="checkbox"
                  checked={hideBackgroundDots}
                  onChange={(event) => setHideBackgroundDots(event.target.checked)}
                />
              </td>
            </tr>
            <tr>
              <td>Image Size</td>
              <td>
                <input
                  type="number"
                  value={imageSize}
                  onChange={(event) => setImageSize(event.target.value)}
                  min={0.1}
                  step={0.1}
                />
              </td>
            </tr>
            <tr>
              <td>Image Margin</td>
              <td>
                <input
                  type="number"
                  value={imageMargin}
                  onChange={(event) => setImageMargin(event.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="qrcode-display">
        <div ref={ref} />
        <div className="download" style={{display: 'flex', marginTop: 20}}>
          <div>
            <select style={{width: 80}} onChange={onExtensionChange}>
              {downloadImageExtension.map((ext) => (
                <option selected={ext === fileExt} key={ext} value={ext}>{ext}</option>
              ))}
            </select>
          </div>
          <div>
            <button onClick={onDownloadClick}>Download</button>
          </div>
        </div>
        <hr style={{border: 'none', borderTop: '1px solid #ccc', margin: '20px 0', width: '80%'}}/>
        <h4>For Developer</h4>
        <div className="download" style={{display: 'flex', marginTop: 5, textAlign: 'left'}}>
          {/* <div>
            <button style={{ backgroundColor: '#346fe5'}} onClick={copyQROptionsAsJson}>Copy QR Code Options</button>
          </div> */}
          <div>
            <b>API Documents:</b> <a href="https://rapidapi.com/dereknguyen269/api/qr-code-generator152" target="_blank" rel="noreferrer">https://rapidapi.com/dereknguyen269/api/qr-code-generator152</a>
            <h5>QrCode Options as Json</h5>
            <ReactJson src={qrCodeOptionsAsJson()} />
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  inputWrapper: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  inputBox: {
    flexGrow: 1,
    marginRight: 20
  }
};
